<template>
  <div class="action-buttons mb-4">
    <b-button variant="danger" class="mr-2" to="/admin/issues/new">Create an Issue</b-button>
  </div>
</template>

<script>
export default {
  name: 'ActionButtons'
}
</script>
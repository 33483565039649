<template>
    <span>
        <b-button size="sm" variant="outline-info" v-on:click="toggle()">{{ (open ? 'Hide ':'') + title }}</b-button>
        <div class="content mt-2" v-if="open">
          {{ content }}
        </div>
    </span>
</template>

<script>
export default {
  name: 'BasicAccordion',
  data: () => ({
    open: false
  }),
  props: {
    'title': String,
    'content': String,
  },
  methods: {
    toggle: function() {
        this.open = !this.open;
    },
  }
}
</script>

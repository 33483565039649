<template>
  <div class="home">
    <b-alert show variant="success" v-if="this.$route.params.message">
      {{this.$route.params.message}}
    </b-alert>

    <b-button v-if="!this.$store.getters.sessionToken" variant="success" class="mr-2" to="/admin/login">Login</b-button>
    <div v-else>
      <ActionButtons class="my-3"/>
      <NewReports class="mb-3" :reports="reports" :issues="issues" />
      <UnresolvedIssues :issues="issues" />
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import ActionButtons from '@/components/admin/ActionButtons.vue'
import NewReports from '@/components/admin/NewReports.vue'
import UnresolvedIssues from '@/components/admin/UnresolvedIssues.vue'
import axios from 'axios'

export default {
  name: 'AdminHome',
  components: {
    ActionButtons,
    NewReports,
    UnresolvedIssues
  },
  data() {
    return {
      reports: [],
      issues: []
    }
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_API_ROOT}/admin/issues/unresolved`, {
        headers: {
          'x-auth-token': this.$store.getters.sessionToken
        }
      })
      .then(response => (this.issues = response.data))

    axios
      .get(`${process.env.VUE_APP_API_ROOT}/admin/reports/unassigned`, {
        headers: {
          'x-auth-token': this.$store.getters.sessionToken
        }
      })
      .then(response => (this.reports = response.data))
  },
  methods: {
    getPlatformColor: function(platform) {
      if (!platform) {
        return '';
      } else if (platform.includes("Xbox")) {
        return 'success';
      } else if (platform.includes("PS4")) {
        return 'primary';
      }
    },
    getIssueColor: function(issue) {
      switch (issue) {
        case 'networking':
          return 'light';
        case 'balance':
          return 'secondary';
        case 'performance':
          return 'dark';
        case 'crash':
          return 'danger';
        case 'gameplay':
          return 'warning';
        case 'user interface':
          return 'info';
      }
    }
  }
}
</script>

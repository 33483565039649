<template>
  <div class="resolved-issues">
    <!-- Modal handle assigning reports to issues -->
    <b-modal id="assign-report-to-issue" title="Assign report to issue" button-size="sm" @ok="assignReport">
      <b-form-select v-model="selectedIssue.id" :options="issues.map(i => ({ text: i.title, value: i.id }))">
        <template #first>
          <b-form-select-option :value="null" disabled>Unresolved Issues</b-form-select-option>
        </template>
      </b-form-select>
    </b-modal>

    <!-- Modal to handle converting reports to issues -->
    <b-modal id="create-issue-from-report" title="Create issue from report" button-size="sm" @ok="createIssueFromReport">
      <b-form-select v-model="selectedIssue.type" :options="['audio', 'graphical', 'gameplay', 'performance', 'networking', 'crash', 'balance', 'user interface', 'quality of life']">
      <template #first>
        <b-form-select-option :value="null" disabled>Issue Type</b-form-select-option>
      </template>
    </b-form-select>
    </b-modal>

    <div class="section-header">
      <span>New Reports</span> - You can assign them to an existing issue
    </div>
    <table class="reports-table" :cols="{default: 2, 768: 1}">
      <tr>
        <th>Title</th>
        <th>Description</th>
        <th>Repro steps</th>        
        <th class="actions">Actions</th>
        <th>Console</th>
        <th>Type</th>        
        <th>Monument</th>      
        <th>Reported by</th>
        <th>Delete</th>
      </tr>
      <tr v-for="report in reports" :key="report.id">            
            <td class="title">{{ report.title }}</td>

            <td>
              <div>
                {{ report.description }}
              </div>              
            </td>

            <td>
              <BasicAccordion v-if="report.repro_steps" title="Repro Steps" :content="report.repro_steps"/>
            </td>

            <td>
              <b-button size="sm" variant="outline-primary" class="mr-2" v-b-modal.create-issue-from-report @click="setSelectedReport(report.id, report.title, report.platform)">Create Issue</b-button>
              <b-button size="sm" variant="outline-primary" class="mr-2" v-b-modal.assign-report-to-issue @click="setSelectedReport(report.id)">Assign to Issue</b-button>                            
            </td>

            <td>
              <b-badge class="mr-1" :variant="$parent.getPlatformColor(report.platform)">
                {{ report.platform }}
              </b-badge>
            </td>

            <td>
              <b-badge :variant="$parent.getIssueColor(report.type)" class="mr-1">{{ report.type }}</b-badge>              
            </td>    

            <td>
              <b-badge variant="secondary" v-if="report.monument">{{ report.monument }}</b-badge>
              <span v-else>N/A</span>
            </td>

            <td>
              <b-badge v-if="report.platform_user" class="float-right" variant="secondary">
                Reported by {{ report.platform_user }}
              </b-badge>              
            </td>
            <td>
              <DeleteButton class="mr-2" resource_type="report" :resource_id="report.id" @resource-deleted="removeReport(report.id)"/>
            </td>
      </tr>
    </table>
  </div>
</template>

<script>
import BasicAccordion from '@/components/admin/BasicAccordion.vue'
import DeleteButton from '@/components/admin/DeleteButton.vue'
import axios from 'axios'

export default {
  name: 'NewReports',
  components: {
    BasicAccordion,
    DeleteButton
  },
  data() { return {
    selectedReport: {
      id: null,
      title: null,
      platform: null,
    },
    selectedIssue: {
      id: null,
      type: null
    },
  }},
  props: {
    reports: Array,
    issues: Array
  },
  methods: {
    // Assigns a user's report to an issue, so it can be referenced for further
    // information about the issue.
    async assignReport() {
      if (!(this.selectedIssue.id && this.selectedReport.id)) {
        alert('Failed to assign report to issue.');
        console.error(`Can't assign report to an issue. Issued ID: ${this.selectedIssue.id}. Report ID: ${this.selectedReport.id}`)
      }

      const result = await axios({
        method: 'post',
        headers: { 'x-auth-token': this.$store.getters.sessionToken },
        url: `${process.env.VUE_APP_API_ROOT}/admin/report/${this.selectedReport.id}/assign`,
        data: { issue_id: this.selectedIssue.id }
      });

      // Only want to remove the report if the deletion was successful
      if (result.status == 200) {
        this.removeReport(this.selectedReport.id);
      }
    },
    setSelectedReport(id, title, platform) {
      this.selectedReport.id = id;
      this.selectedReport.title = title;
      this.selectedReport.platform = platform;
    },
    // Convert a user's report into an issue, allowing other related reports
    // to be assigned to it
    async createIssueFromReport() {
      const result = await axios({
        method: 'post',
        headers: { 'x-auth-token': this.$store.getters.sessionToken },
        url: `${process.env.VUE_APP_API_ROOT}/admin/report/${this.selectedReport.id}/issue`,
        data: { 
          title: this.selectedReport.title,
          issue_type: this.selectedIssue.type,
          platforms: [this.selectedReport.platform] 
        }
      });

      if (result.status == 200) {
        this.removeReport(this.selectedReport.id);
      }
    },
    // Remove a report from the locally stored reports, usually after
    // a request to the API has either assigned it to an issue, deleted it,
    // or created a report from it
    removeReport(report_id) {
      console.log('Looking to remove report with id', report_id);
      const element_position = this.reports.findIndex(r => r.id == report_id);

      if (element_position === -1) {
        console.error('element not found');
        return;
      }
      this.reports.splice(element_position, 1);
    }
  },
}
</script>

<style lang="scss" scoped>
  .reports-table {
    width: 100%;

    th, td {
      padding: 1rem;
      font-size: 1rem;
    }
      
    th {
      background: #152238;
      color: #ffffff;
      white-space: nowrap;
    }

    tr {
      background: #f6f6f6;

      &:nth-child(even) {
        background: #ffffff;
      }
    }

    .actions {
      width: 17rem;
    }

    .btn {
      white-space: nowrap;
    }
  }
</style>
<template>
    <b-button size="sm" variant="danger" v-on:click="confirm()">{{ buttonText }}</b-button>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeleteButton',
  data: () => ({
    clicked: false,
    buttonText: 'Delete'
  }),
  props: ['resource_id', 'resource_type'],

  methods: {
    async confirm() {
      if (!this.clicked) {
        this.buttonText = "Are you sure?"
        this.clicked = true
      } else {
        const result = await axios.delete(`${process.env.VUE_APP_API_ROOT}/admin/${this.resource_type}/${this.resource_id}`, {
            headers: {'x-auth-token': this.$store.getters.sessionToken}})
        if (result.status == 200) {
          this.$emit('resource-deleted')
        }
      }
    },
  }
}
</script>

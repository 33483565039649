<template>
  <div class="resolved-issues">
    <div class="section-header"><span>Unresolved Issues</span></div>
    <masonry :cols="{default: 3, 768: 1}">
      <div v-for="issue in issues" :key="issue.id" class="issue p-2 mx-1">
        <div class="d-flex flex-row mb-3">
            <b-badge class="align-self-baseline mr-3"><span class="count">{{ issue.report_count }}</span><br>Reports</b-badge>
            <div>
                <div class="title">{{ issue.title }} <span class="issue-id">ID: {{ issue.id }}</span></div>
                <b-badge :variant="$parent.getIssueColor(issue.type)" class="mr-1">{{ issue.type }}</b-badge>
                <b-badge v-for="platform in issue.platforms" :key="platform" class="mr-1" :variant="$parent.getPlatformColor(platform)">                  
                    {{ platform }}
                </b-badge>
                <div>
                {{ issue.description }}
                </div>
            </div>
        </div>
        <b-button v-if="issue.in_progress" size="sm" variant="success" class="mr-2" disabled>In Progress</b-button>
        <b-button v-else size="sm" variant="success" class="mr-2" @click="issueInProgress(issue.id)">Mark In Progress</b-button>
        <b-button size="sm" variant="success" class="mr-2" @click="resolveIssue(issue.id)">Resolve</b-button>
        <b-button size="sm" variant="success" class="mr-2" @click="toggleHideIssue(issue.id, !issue.hidden)">          
          <span>{{ issue.hidden ? 'Show' : 'Hide' }} Issue</span>
        </b-button>
        <ReportsAccordion title="Report information" :issue_id="issue.id"/>
      </div>
    </masonry>
  </div>
</template>

<script>
import axios from 'axios'
import ReportsAccordion from '@/components/admin/ReportsAccordion.vue'

export default {
  name: 'UnresolvedIssues',
  components: {
    ReportsAccordion
  },
  props: {
    issues: Array
  },
  methods: {
    async resolveIssue(issue_id) {
      const result = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_ROOT}/admin/issue/${issue_id}/resolve`,
          headers: {
            'x-auth-token': this.$store.getters.sessionToken
          }
        });
      if (result.status == 200) {
        const element_position = this.issues.findIndex(i => i.id == issue_id);

        if (element_position === -1) {
          console.error('element not found');
          return;
        }

        this.issues.splice(element_position, 1);
      }
    },
    async issueInProgress(issue_id) {
      const result = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_ROOT}/admin/issue/${issue_id}/in_progress`,
          headers: {
            'x-auth-token': this.$store.getters.sessionToken
          }
        });
      if (result.status == 200) {
        const element_position = this.issues.findIndex(i => i.id == issue_id);

        if (element_position === -1) {
          console.error('element not found');
          return;
        }

        this.issues[element_position].in_progress = true;
      }
    },   
    async toggleHideIssue(issue_id) {

      const result = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_ROOT}/admin/issue/${issue_id}/toggle_visible`,
          headers: {
            'x-auth-token': this.$store.getters.sessionToken
          }
        });
      if (result.status == 200) {
        const element_position = this.issues.findIndex(i => i.id == issue_id);

        if (element_position === -1) {
          console.error('element not found');
          return;
        }

        this.issues[element_position].hidden = !this.issues[element_position].hidden;

      }
    },        
  }
}
</script>

<style lang="scss" scoped>
.issue-id {
  color: lighten(#3c363e, 50%);
  font-size: 1.25rem;
}
</style>

<template>
    <span>
        <b-button size="sm" variant="outline-info" v-on:click="toggle()">{{ (open ? 'Hide ':'') + title }}</b-button>
        <div class="content mt-2" v-if="open">
          <span v-if="reports === null">Loading reports...</span>
          <span v-else-if="reports.length == 0">No reports.</span>
          <div v-else>
            <span v-for="r of reports" :key="r.id">
              <span class="font-weight-bold">{{ r.title }}</span>
              <div>{{ r.description }}</div>
              <span v-if="r.repro_steps"><span class="font-weight-bold">Repro Steps:</span> {{ r.repro_steps }}</span>
              <div class="badges">
                <b-badge variant="secondary" v-if="r.monument">Monument: {{ r.monument }}</b-badge>
              </div>
              <hr>
            </span>
          </div>
        </div>
    </span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ReportsAccordion',
  data: () => ({
    open: false,
    reports: null
  }),
  props: {
    'title': String,
    'content': String,
    'issue_id': Number
  },
  methods: {
    toggle: function() {
        this.open = !this.open;
        if (this.open) {
          this.getIssueReports();
        }
    },
    async getIssueReports() {
      const result = await axios
        .get(`${process.env.VUE_APP_API_ROOT}/admin/issue/${this.issue_id}/reports`, {
          headers: {
            'x-auth-token': this.$store.getters.sessionToken
          }
        })
      
      this.reports = result.data
    },
    getPlatformColor: function(platform) {
      if (!platform) {
        return '';
      } else if (platform.includes("Xbox")) {
        return 'success';
      } else if (platform.includes("PS4")) {
        return 'primary';
      }
    },
  }
}
</script>
